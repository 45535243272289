import { useQuery } from '@apollo/client';
import { GetAllClients as GetAllClientsType } from '@generated/types';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { GetAllClients } from '../_services/clients/queries';
import DynamicClientCard from './client-card/DynamicClientCard';

interface Props {
  location: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 40,
    },
  }),
);

export default function ClientDashboard({ location }: Props) {
  const classes = useStyles();

  const { data } = useQuery<GetAllClientsType>(GetAllClients, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify={'center'}>
          <Grid item xs={10}>
            {data?.getAllClients?.map((client: Readonly<any>) => {
              return (
                <DynamicClientCard logo={client.logo} name={client.name} url={client.url} workspaceId={client.id} />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
