import { Grid } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import TnTypography from '../tn-typography/TnTypography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    zIndex: '9000',
  },

  label: {
    fontSize: '16px',
    color: '#292929',
  },

  menuItem: {
    height: 48,
    paddingLeft: '18px',
    '&:hover': {
      backgroundColor: '#DCECFF',
    },
  },

  email: {
    height: '14px',
    color: '#C9C9C9',
    fontSize: '10px',
    fontWeight: 500,
    wordBreak: 'break-all',
  },

  menuItemLabel: {
    fontFamily: 'Avenir',
    fontSize: '10px',
    color: '#292929',
    '&:hover': {
      fontWeight: 'bold',
    },
  },

  paperRoot: {
    zIndex: '9000',
  },

  buttonBack: {
    backgroundColor: 'white !important',
  },

  dropDownButton: {
    '&:hover': {
      color: '#0074F2',
    },
  },

  menu: {
    // width: 143,
    zIndex: 1000000,
    boxShadow: '6px 3px 14px 6px rgba(0,0,0,0.08)',
  },

  menuHeader: {
    paddingLeft: '18px',
    height: '40px',
  },

  popper: {
    zIndex: 100000,
  },

  iconButtonRoot: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },

  menuitemRoot: {
    width: '100px',
  },

  menuListPadding: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
});

class MenuDropDown extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div>
          <Popper
            open={this.props.open}
            anchorEl={this.props.event}
            placement={'bottom-end'}
            transition
            disablePortal
            // className={classes.popper}
            style={{ position: 'relative', zIndex: 100000000 }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} id="menu-list-grow">
                <Paper elevation={2} classes={{ root: classes.paperRoot }} className={classes.menu}>
                  <ClickAwayListener onClickAway={this.props.handleClose}>
                    <MenuList classes={{ padding: classes.menuListPadding }}>
                      {this.props.menuItems.map((element, index) => (
                        <MenuItem
                          key={element.label + index}
                          className={classes.menuItem}
                          onClick={(event) => {
                            element.onclick();
                            this.props.handleClose(event);
                          }}
                          data-testid={element.label}
                        >
                          <Grid container alignItems="center" direction="row" justify="flex-start">
                            <Grid item>
                              <TnTypography variant="overline">{element.label}</TnTypography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MenuDropDown);
