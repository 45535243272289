import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { history } from '../../_helpers/history';
import BackArrowButton from '../../static/_assets/back-button/back-button.svg';

interface TnBackButtonProps {
  classes?: any;
  onClick?: any;
}

class TnBackButton extends Component<TnBackButtonProps, any> {
  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    let { classes, onClick } = this.props;
    return (
      <img
        src={BackArrowButton}
        alt={'Back button'}
        className={classes.backButton}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            history.go(-1);
          }
        }}
      />
    );
  }
}

export default withStyles((theme) => ({
  backButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(TnBackButton);
