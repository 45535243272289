import jwtDecode from 'jwt-decode';
import React, { Component } from 'react';

import Loader from '../_components/Loader';
import { getUserByAuth0Id } from '../_helpers/CallbackHelpers';
import { history } from '../_helpers/history';
import auth from '../_services/auth/Auth';

class Callback extends Component {
  componentDidMount() {
    auth.auth0.parseHash((err: any, authResult: any) => {
      console.log(err);
      if (err) return Promise.reject(err);
      if (!authResult?.idToken) {
        return Promise.reject(err);
      }

      const decodedIdToken: any = jwtDecode(authResult.idToken);
      const decodedAccessToken: any = jwtDecode(authResult.accessToken);
      const scopes = decodedAccessToken.scope.split(' ');
      const role = scopes[scopes.length - 1];
      if (decodedIdToken['https://talentnow.ai/loginsCount'] == '1') {
        history.push({
          pathname: '/login',
          state: {
            innerComponent: 'changePassword',
            auth0Id: decodedIdToken.sub,
            accessToken: authResult.accessToken,
          },
        });

        return;
      } else {
        auth.setSession(authResult);
        getUserByAuth0Id(decodedIdToken.sub, role);
      }
    });
  }

  render() {
    return <Loader />;
  }
}

export default Callback;
