import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import classNames from 'classnames';
import React from 'react';

import TnTypography from '../tn-typography/TnTypography';

interface IProps {
  classes?: any;
  open: boolean;
  handleClose: any;
  headerLabel?: string;
  hideBackdrop?: boolean;
  customClass?: any;
  bigger?: boolean;
  extraLarge?: any;
}

class TnModal extends React.Component<IProps, any> {
  componentDidMount() {
    document.addEventListener('keydown', this.handleEscapeKey, false);
  }

  handleEscapeKey = (event: any) => {
    if (event.keyCode === 27) {
      this.props.handleClose();
    }
  };

  render() {
    const { classes, customClass, open, handleClose, headerLabel, hideBackdrop, children, bigger, extraLarge } =
      this.props;

    return (
      <Modal open={open} hideBackdrop={hideBackdrop ? true : false} style={{ zIndex: 10006, overflow: 'scroll' }}>
        <Paper
          elevation={2}
          className={
            customClass
              ? customClass
              : classNames({
                  [classes.paper]: true,
                  [classes.biggerModal]: bigger,
                  [classes.extraLargeModal]: extraLarge,
                })
          }
        >
          <Grid container>
            <Grid item xs={12} className={classes.header}>
              <Grid container justify={'space-around'}>
                <Grid item xs={10}>
                  <Grid container alignItems={'center'} className={classes.headerTitleBar}>
                    <Grid item>
                      <TnTypography variant="subtitle2" className={classes.headerTitle}>
                        {headerLabel}
                      </TnTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid container alignItems={'center'} justify={'flex-end'} className={classes.headerTitleBar}>
                    <Grid item>
                      <Close className={classes.close} onClick={handleClose} data-testid={'Modal_Close'} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {children}
          </Grid>
        </Paper>
      </Modal>
    );
  }
}

export default withStyles((theme: any) => ({
  paper: {
    position: 'relative',
    width: '33.62%',
    minHeight: '231px',
    outline: 'none',
    backgroundColor: 'white',
    borderRadius: 0,
    top: '98.46px',
    margin: '0 auto',
    [theme.breakpoints.down('1080')]: {
      width: '90%',
    },
  },
  biggerModal: {
    width: '50%',
    [theme.breakpoints.down('1080')]: {
      width: '90%',
      overflowX: 'auto',
    },
  },
  extraLargeModal: {
    width: '85%',
    [theme.breakpoints.down('1080')]: {
      width: '90%',
      overflowX: 'auto',
    },
  },
  header: {
    backgroundColor: '#526173',
    height: 45,
  },
  headerTitle: {
    color: '#ffffff',
  },
  headerTitleBar: {
    height: 45,
  },
  close: {
    // position: 'absolute',
    // right: '11.33px',
    // top: '10px',
    color: '#ffffff',
    cursor: 'pointer',
  },
}))(TnModal);
