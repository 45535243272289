import { Grid, Tab, Tabs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import jwtDecode from 'jwt-decode';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { history } from '../../_helpers/history';
import { getSuffixOfImageId } from '../../_helpers/ProfilePicHelper';
import auth from '../../_services/auth/Auth';
import BitmapImg from '../../static/_assets/koncert-logo.svg';
import MenuDropDown from '../menu-options/MenuDropDown';
import { hasPermission } from '../permissions/CheckPermission';
import TnAvatar from '../tn-avatar/TnAvatar';
import TnTypography from '../tn-typography/TnTypography';

interface IProps extends RouteComponentProps<any> {
  [key: string]: any;
}

interface Tab {
  value: any;
  label: any;
  onclick: (...args: any[]) => any;
}

interface IState {
  tabs: Array<Tab>;
  [key: string]: any;
}

const dashboard: Tab = {
  value: 'clients',
  label: 'Clients',
  onclick: () => {
    history.push('/');
  },
};

const job: Tab = {
  value: 'jobs',
  label: 'Jobs',
  onclick: () => {
    history.push('/jobs');
  },
};

const candidates: Tab = {
  value: 'candidates',
  label: 'Candidates',
  onclick: () => {
    history.push('/candidates');
  },
};

const marketplace: Tab = {
  value: 'marketplace',
  label: 'Marketplace',
  onclick: () => {
    history.push('/marketplace');
  },
};

const vendor: Tab = {
  value: 'vendors',
  label: 'Vendors',
  onclick: () => {
    history.push('/vendors');
  },
};

const profile: Tab = {
  value: 'profile',
  label: 'Profile',
  onclick: () => {
    history.push('/vendorProfile/profile');
  },
};

const settings: Tab = {
  value: 'systemSettings',
  label: 'Admin Settings',
  onclick: () => {
    history.push('/systemSettings/user');
  },
};

class NavigationBar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: localStorage.getItem('currentTab') ? localStorage.getItem('currentTab') : 'dashboard',
      tabs: this.getTabsBasedOnRole(props.role),
      switchRole: false,
      isEligibleForMarketplace: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.getMenuItems = this.getMenuItems.bind(this);
  }

  componentWillMount() {
    this.addAdminSettingsTab();
  }

  getMenuItems() {
    const menuItemObj = [
      {
        label: 'Logout',
        onclick: () => {
          history.push('/login');
        },
      },
    ];

    const decodedIdToken: any =
      auth.isTokenValid() && auth.getIdTokenFromLocalStorage() && jwtDecode(auth.getIdToken() as string);
    const roles = decodedIdToken['https://talentnow.userinfo.com/usermetadata'].role.split(',');

    const switchObj = {
      label: 'Switch Role',
      onclick: () => {
        this.setState({
          switchRole: true,
          openMenu: true,
        });
      },
    };

    if (roles.length > 1) {
      menuItemObj.splice(1, 0, switchObj);
    }

    return menuItemObj;
  }

  getRoles() {
    const decodedIdToken: any =
      auth.isTokenValid() && auth.getIdTokenFromLocalStorage() && jwtDecode(auth.getIdToken() as string);
    const decodedAccessToken: any =
      auth.isTokenValid() && auth.getAccessToken() && jwtDecode(auth.getAccessToken() as string);
    const roles = decodedIdToken['https://talentnow.userinfo.com/usermetadata'].role.split(',');
    let currentRole = decodedAccessToken.scope.split(' ');
    currentRole = currentRole[currentRole.length - 1];
    roles.splice(roles.indexOf(currentRole), 1);
    const role_list = [];
    roles.sort();

    for (let i = 0; i < roles.length; i++) {
      const obj = { label: '', onclick: () => {} };
      obj.label = 'Switch to ' + roles[i];
      obj.onclick = () => {
        auth.silentAuth(roles[i], true);
      };
      role_list.push(obj);
    }

    return role_list;
  }

  getCurrentTab() {
    const currentPath = auth.getCurrentRoute();
    if (currentPath.split('/')[1] === '') {
      return 'clients';
    } else if (currentPath.startsWith('/candidates')) {
      return 'candidates';
    } else if (currentPath.startsWith('/marketplace')) {
      return 'marketplace';
    } else if (currentPath.startsWith('/vendorOnboarding') || currentPath.startsWith('/vendors')) {
      return 'vendors';
    } else if (currentPath.startsWith('/vendorProfile')) {
      return 'profile';
    } else if (currentPath.startsWith('/systemSettings')) {
      return 'systemSettings';
    }
    if (currentPath.startsWith('/jobs') || currentPath.startsWith('/createJob')) {
      return 'jobs';
    }
  }

  getTabsBasedOnRole = (role: string) => {
    const tabs = [dashboard];
    switch (role.toLocaleUpperCase()) {
      case 'MSP':
        tabs.push(job, candidates);
        if (process.env.MARKETPLACE_V2_ENABLED === 'true') {
          tabs.push(vendor);
        }
        break;
      case 'VENDOR':
        tabs.push(marketplace, job);
        if (process.env.MARKETPLACE_V2_ENABLED === 'true') {
          tabs.push(profile);
        }
        break;
    }

    return tabs;
  };

  handleChange(value: any) {
    localStorage.setItem('currentTab', value);
    this.setState({ value: value });
  }

  handleAvatarClick(event: any) {
    const { currentTarget } = event;
    this.setState((state) => ({
      openMenu: !state.openMenu,
      event: currentTarget,
    }));
  }

  handleClose() {
    this.setState({ openMenu: false });
  }

  roleHandleClose() {
    this.setState({
      openMenu: false,
      switchRole: false,
    });
  }

  addAdminSettingsTab() {
    if (process.env.MARKETPLACE_V2_ENABLED === 'true') {
      hasPermission('system_settings')
        .then((hasSystemSettingsPermission) => {
          if (hasSystemSettingsPermission) {
            this.setState((prevState) => {
              const currentTabs = [...prevState.tabs];
              currentTabs.push(settings);

              return { tabs: currentTabs };
            });
          }
        })
        .catch((error: string) => {
          console.error('Error: ', error);
        });
    }
  }

  render() {
    const currentTab = this.getCurrentTab();
    const { classes, userName, role, imageId } = this.props;
    const { tabs } = this.state;

    return (
      <Grid container className={classes.base} justify="center">
        <Grid item xs={11} className={classes.navbarContainer}>
          <div className={classes.headerTopRow}>
            <div className={classes.koncertAvatarGrid}>
              <img
                alt="home-page"
                onClick={() => history.push('/')}
                src={BitmapImg}
                className={classes.koncertAvatar}
                data-testid={'KoncertIt_Logo'}
              />
            </div>
            <div className={classes.upperMenu}>
              <Grid container>
                <Grid item xs={12}>
                  <Tabs
                    value={currentTab}
                    classes={{
                      root: classes.tabsRoot,
                      indicator: classes.tabsIndicator,
                      fixed: classes.scrollableFixed,
                    }}
                  >
                    {tabs?.map((tab, index: number) => {
                      return (
                        <MyTab
                          value={tab.value}
                          label={tab.label}
                          key={tab.label + index}
                          onClick={() => {
                            this.handleChange(tab.value);
                            tab.onclick();
                          }}
                          data-testid={tab.label}
                        />
                      );
                    })}
                  </Tabs>
                </Grid>
              </Grid>
            </div>
            <div className={classes.flexGrow} />
            <div className={classes.flexRow}>
              <div className={classes.flexRow}>
                <TnAvatar src={getSuffixOfImageId(imageId)} name={userName} />
                <TnTypography variant="caption2" className={classes.profileName}>
                  {userName + ' ' + '(' + role + ')'}
                </TnTypography>
                <IconButton
                  style={{ padding: 0, color: 'black' }}
                  aria-label="Delete"
                  color="primary"
                  onClick={(event) => this.handleAvatarClick(event)}
                  data-testid={'Navbar_Menu_Dropdown'}
                >
                  <ExpandMore />
                </IconButton>
                {!this.state.switchRole && (
                  <MenuDropDown
                    open={this.state.openMenu}
                    event={this.state.event}
                    handleClose={() => this.handleClose()}
                    menuItems={this.getMenuItems()}
                  />
                )}
                {this.state.switchRole && (
                  <MenuDropDown
                    open={true}
                    event={this.state.event}
                    handleClose={() => this.roleHandleClose()}
                    menuItems={this.getRoles()}
                  />
                )}
              </div>
            </div>
          </div>
          <Grid container className={classes.lowerMenu}>
            <Grid item xs={12}>
              <Tabs
                value={currentTab}
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                  fixed: classes.scrollableFixed,
                }}
              >
                {tabs?.map((tab, index: number) => {
                  return (
                    <MyTab
                      value={tab.value}
                      label={tab.label}
                      key={tab.label + index}
                      onClick={() => {
                        this.handleChange(tab.value);
                        tab.onclick();
                      }}
                      data-testid={tab.label}
                    />
                  );
                })}
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles((theme) => ({
  base: {
    height: 61,
    width: '100%',
    backgroundColor: 'white',
    position: 'fixed',
    zIndex: 10005,
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.1)',
  },
  navbarContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 1920,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  tabsRoot: {
    borderBottom: '0px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: 'unset',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  koncertAvatarGrid: {
    marginRight: '20px',
  },
  koncertAvatar: {
    width: 30,
    cursor: 'pointer',
    padding: '1.305% 0px 0px 0px',
    display: 'block',
  },
  upperMenu: {
    marginLeft: 20,
    ['@media (max-width:700px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  lowerMenu: {
    display: 'none',
    ['@media (max-width:700px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'block',
    },
  },
  profileName: {
    height: 16,
    width: 120,
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
  },
  headerTopRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}))(withRouter(NavigationBar));

const MyTab = withStyles((theme) => ({
  root: {
    textTransform: 'initial',
    minWidth: 10,
    paddingLeft: 0,
    height: 59,
    fontSize: '14px',
    fontWeight: 10,
    color: 'rgba(26, 22, 23, 0.92)',
    opacity: 1,
    marginRight: 40,
    '&:last-child': {
      paddingRight: 0,
    },
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    borderBottom: '3px solid #ffffff',
    borderImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.secondary.main}) 1 stretch`,
  },
  wrapper: {
    padding: 'unset',
    fontFamily: 'Avenir medium',
  },
  label: {
    height: 20,
    width: 66,
    fontSize: 16,
    lineHeight: 'unset',
  },
  indicator: {
    display: 'none',
  },
  button: {
    padding: '0px',
  },
}))(Tab);
