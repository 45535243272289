import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';

import { FONT_BLACK, FONT_BOOK, FONT_HEAVY, FONT_MED } from '../../constants/Styles';

type Color =
  | 'blue'
  | 'light'
  | 'lightGrey'
  | 'grey'
  | 'orange'
  | 'primary'
  | 'primaryLight'
  | 'secondary'
  | 'red'
  | 'white'
  | 'green'
  | 'darkGrey';

interface TnTypographyProps {
  classes?: any;
  children: any;
  variant:
    | 'h0'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h6'
    | 'subtitle'
    | 'subtitle2'
    | 'body'
    | 'body2'
    | 'bodyBold'
    | 'overline'
    | 'caption'
    | 'caption2'
    | 'caption2ForLargeText'
    | 'caption2Bold'
    | 'caption3'
    | 'caption4'
    | 'caption5'
    | 'button'
    | 'dashboardTitle'
    | 'reportLink';
  className?: any;
  isParagraph?: boolean;
  bold?: boolean;
  italicize?: boolean;
  color?: Color;
  dataTestId?: string;
  onClick?: (...args: any[]) => any;
  display?: 'initial' | 'block' | 'inline';
  component?: any;
}

class TnTypography extends Component<TnTypographyProps, any> {
  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | Record<string, unknown>
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    const {
      children,
      variant,
      classes,
      className,
      isParagraph,
      color,
      display,
      dataTestId,
      component,
      bold,
      italicize,
    } = this.props;

    return (
      <Typography
        className={classNames({
          [classes.defaultColorClass]: color === undefined,
          [className]: className !== undefined,
          [classes.h0]: variant === 'h0',
          [classes.h1]: variant === 'h1',
          [classes.h2]: variant === 'h2',
          [classes.h3]: variant === 'h3',
          [classes.h4]: variant === 'h4',
          [classes.h6]: variant === 'h6',
          [classes.subtitle]: variant === 'subtitle',
          [classes.subtitle2]: variant === 'subtitle2',
          [classes.body]: variant === 'body',
          [classes.body2]: variant === 'body2',
          [classes.bodyBold]: variant === 'bodyBold',
          [classes.overline]: variant === 'overline',
          [classes.caption]: variant === 'caption',
          [classes.caption2]: variant === 'caption2',
          [classes.caption2ForLargeText]: variant === 'caption2ForLargeText',
          [classes.caption2Bold]: variant === 'caption2Bold',
          [classes.caption3]: variant === 'caption3',
          [classes.caption4]: variant === 'caption4',
          [classes.caption5]: variant === 'caption5',
          [classes.button]: variant === 'button',
          [classes.dashboardTitle]: variant === 'dashboardTitle',
          [classes.bold]: bold,
          [classes.italicize]: italicize,
          [classes.blueColor]: color === 'blue',
          [classes.lightColor]: color === 'light',
          [classes.greyColor]: color === 'grey',
          [classes.orangeColor]: color === 'orange',
          [classes.primaryColor]: color === 'primary',
          [classes.primaryLightColor]: color === 'primaryLight',
          [classes.secondaryColor]: color === 'secondary',
          [classes.redColor]: color === 'red',
          [classes.whiteColor]: color === 'white',
          [classes.lightGreyColor]: color === 'lightGrey',
          [classes.darkGrey]: color === 'darkGrey',
          [classes.greenColor]: color === 'green',
        })}
        paragraph={isParagraph}
        component={component}
        onClick={this.props.onClick}
        display={display}
        data-testid={dataTestId == null ? children + '_Label' : dataTestId}
      >
        {children}
      </Typography>
    );
  }
}

export default withStyles((theme) => ({
  defaultColorClass: {
    color: '#1C1E20',
  },
  h0: {
    fontSize: '60px',
    ...FONT_HEAVY,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(56)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(54),
    },
  },
  h1: {
    fontSize: '32px',
    ...FONT_BLACK,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(30)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(26),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(34)
    // }
  },
  h2: {
    fontSize: '26px',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(24)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(22),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(28)
    // }
  },
  h3: {
    fontSize: '24px',
    ...FONT_HEAVY,
    lineHeight: '30px',
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(22)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(20),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(26)
    // }
  },
  h4: {
    fontSize: theme.typography.pxToRem(26),
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(24)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(22),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(28)
    // }
  },
  h6: {
    fontSize: theme.typography.pxToRem(22),
    lineHeight: '28px',
    ...FONT_HEAVY,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(20)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(20),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(30)
    // }
  },
  subtitle: {
    fontSize: '22px',
    lineHeight: '23px',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(20)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(18),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(24)
    // }
  },
  subtitle2: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '18px',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(14)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(14),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(18)
    // }
  },
  body: {
    fontSize: '18px',
    lineHeight: '19px',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(16)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(16),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(20)
    // }
  },
  bodyBold: {
    fontSize: '18px',
    lineHeight: '19px',
    ...FONT_HEAVY,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(16)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(16),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(20)
    // }
  },
  overline: {
    fontSize: '14px',
    lineHeight: '16px',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(12)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(12),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(16)
    // }
  },
  caption: {
    fontSize: '16px',
    lineHeight: '24px',
    ...FONT_BOOK,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(12)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(12),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(18)
    // }
  },
  caption2: {
    fontSize: '14px',
    lineHeight: '18px',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(12)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(12),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(16)
    // }
  },
  caption2ForLargeText: {
    fontSize: '14px',
    lineHeight: '18px',
    overflowWrap: 'break-word',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(12)
    // },
    [theme.breakpoints.down(1600)]: {
      fontSize: theme.typography.pxToRem(12),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(16)
    // }
  },
  caption2Bold: {
    fontSize: '14px',
    lineHeight: '18px',
    ...FONT_HEAVY,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(12)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(12),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(16)
    // }
  },
  caption3: {
    fontSize: '12px',
    lineHeight: '13px',
    ...FONT_MED,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(10)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(10),
    },
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(14)
    // }
  },
  caption4: {
    fontSize: '10px',
    ...FONT_MED,
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(12)
    // }
  },

  caption5: {
    fontSize: '14px',
    lineHeight: '24px',
    ...FONT_BOOK,
    fontWeight: 400,
    // [theme.breakpoints.only('lg')]:{
    //     fontSize: theme.typography.pxToRem(12)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  button: {
    fontSize: '16px',
    lineHeight: '16px',
    ...FONT_BOOK,
    // [theme.breakpoints.between('md','lg')]:{
    //     fontSize: theme.typography.pxToRem(12)
    // },
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(12),
    },
    cursor: 'pointer',
    // [theme.breakpoints.up(2000)]:{
    //     fontSize: theme.typography.pxToRem(16)
    // }
  },
  dashboardTitle: {
    fontSize: '50px',
    ...FONT_HEAVY,
    [theme.breakpoints.down(1280)]: {
      fontSize: theme.typography.pxToRem(45),
    },
  },
  darkGrey: {
    color: '#494949',
  },
  lightColor: {
    color: '#606060',
    // color: '#ACB6BF'
  },
  greyColor: {
    color: '#757575',
  },
  lightGreyColor: {
    color: '#B3B3B3',
  },
  blueColor: {
    color: '#0073b1',
    // color: '#0000EE'
  },
  redColor: {
    color: '#B00020',
  },
  whiteColor: {
    color: '#ffffff',
  },
  orangeColor: {
    color: '#F45D22',
  },
  greenColor: {
    color: '#27AE60',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  primaryLightColor: {
    color: theme.palette.primary.light,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  bold: {
    ...FONT_HEAVY,
  },
  italicize: {
    fontStyle: 'italic',
  },
}))(TnTypography);
