import { createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import React from 'react';
import VoraLogo from '../../static/_assets/vora_logo.png';

interface Props {
  readonly logo: any;
  readonly name: string;
  readonly url: string;
  readonly workspaceId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 200,
      cursor: 'pointer',
      margin: 10,
    },
    card: {
      height: 130,
    },
    cardImage: {
      maxWidth: '180px',
      maxHeight: '80px',
      width: '100%',
      height: '100%',
    },
  }),
);

export default function DynamicClientCard({ logo, name, url, workspaceId }: Props) {
  const classes = useStyles();

  function redirectToClientApp() {
    let idToken = localStorage.getItem('idToken');
    let accessToken = localStorage.getItem('accessToken');
    window.open(`${url}mpclientRedirect?idToken=${idToken}&accessToken=${accessToken}&workspaceId=${workspaceId}`);
  }

  function getClientLogo() {
    if (logo?.startsWith('http')) {
      return logo;
    }

    return VoraLogo;
  }

  return (
    <Paper className={classes.root} onClick={redirectToClientApp}>
      <Grid container justify={'center'} alignItems={'center'} className={classes.card}>
        <Grid item>
          <img src={getClientLogo()} alt={name} className={classes.cardImage} />
          <Grid container justify="center">
            {name}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
