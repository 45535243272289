import { GET_LOGGEDIN } from '@services/client-quieries/clienttypes';
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';

import { client } from '../..';
import { AuthConfig } from '../../_helpers/AuthConfig';
import { history } from '../../_helpers/history';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: AuthConfig.domain,
      clientID: AuthConfig.clientID,
      redirectUri: process.env.REDIRECT_URI,
      audience: AuthConfig.audience,
      scope: 'openid email',
      responseType: 'token id_token',
    });

    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getIdTokenFromLocalStorage = this.getIdTokenFromLocalStorage.bind(this);
    this.isTokenValid = this.isTokenValid.bind(this);
  }

  login(role, email, password, callback) {
    const loginData = {
      email: email,
      password: password,
      realm: 'Username-Password-Authentication',
    };
    this.auth0.login(loginData, (err, res) => callback(err));
  }

  getIdToken() {
    const token = this.getIdTokenFromLocalStorage();
    if (token) {
      return token;
    } else {
      history.push('/');
    }
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  getIdTokenFromLocalStorage() {
    return localStorage.getItem('idToken');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const decodedAccessToken = jwtDecode(this.getAccessToken());
    const expiresAt = decodedAccessToken.exp * 1000;

    return new Date().getTime() < expiresAt;
  }

  isSessionActive() {
    // Check whether the current time is past the
    // access token's expiry time
    const decodedIdToken = jwtDecode(this.getIdTokenFromLocalStorage());
    const expiresAt = decodedIdToken.exp * 1000;

    return new Date().getTime() < expiresAt;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }

  getAuthHeader() {
    if (this.isTokenValid()) {
      return this.getAccessToken();
    } else if (
      window.location.pathname.indexOf('addVendorDetails') !== 1 &&
      window.location.pathname.indexOf('jobApproval') !== 1 &&
      window.location.pathname.indexOf('interviewfeedback') !== 1 &&
      window.location.pathname.indexOf('preinterviewfeedback') !== 1 &&
      window.location.pathname.indexOf('signOffer') !== 1 &&
      window.location.pathname.indexOf('interviewdetails') !== 1
    ) {
      history.push('/login');
    }
  }

  getCurrentRoute() {
    return window.location.pathname;
  }

  isTokenValid() {
    if (this.getIdTokenFromLocalStorage()) {
      if (!this.isSessionActive()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  setSession(authResult) {
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('idToken', authResult.idToken);

    return true;
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('currentTab');
    client.writeQuery({ query: GET_LOGGEDIN, data: { isLoggedIn: false } });
  }

  async silentAuth(
    role = jwtDecode(auth.getAccessToken()).scope.split(' ')[
      jwtDecode(auth.getAccessToken()).scope.split(' ').length - 1
    ],
    redirect = false,
  ) {
    const promise = new Promise((resolve, reject) => {
      this.auth0.checkSession({ scope: role }, (err, authResult) => {
        if (err) {
          client.writeQuery({ query: GET_LOGGEDIN, data: { isLoggedIn: false } });

          return reject(err);
        } else {
          localStorage.setItem('accessToken', authResult.accessToken);
          resolve(authResult.accessToken);
          if (redirect) {
            history.push('/');
            window.location.reload();
          }
        }
      });
    });

    return await promise;
  }
}

const auth = new Auth();

export default auth;
