/**
 * React polyfill should be always at the top of the import  statements
 */
import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import * as Sentry from '@sentry/browser';
import jwtDecode from 'jwt-decode';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';

import App from './App';
// import  './I18nConfig';
import { history } from './_helpers/history';
import auth from './_services/auth/Auth';
import { GET_LOGGEDIN, typeDefs } from './_services/client-quieries/clienttypes';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './store/ThemeContext';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

// Create customFetch function for handling re-authorization
// This customFetch (or any fetch you pass to the link) gets uri and options as arguments. We'll use those when we actually execute a fetch.
// const customFetch = (uri: string, options: any) => {
const customFetch = (input: RequestInfo | URL, options: any): Promise<Response> => {
  // In our application, the refresh token is stored in a redux store
  // We create an instance of the state here so we can get the refresh token later in our request

  // This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
  let refreshingPromise: any = null;

  // Create initial fetch, this is what would normally be executed in the link without the override
  const initialRequest = fetch(input, options);
  // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
  // Here we return the initialRequest promise

  return initialRequest
    .then((response) => {
      return response.text();
    })
    .then((text) => {
      const json = JSON.parse(text);
      // We should now have the JSON from the response of initialRequest
      // We check that we do and look for errors from the GraphQL server
      // If it has the error 'User is not logged in' (that's our implementation of a 401) we execute the next steps in the re-auth flow
      if (json && json.errors && json.errors[0] && json.errors[0].message === 'jwt expired') {
        if (!refreshingPromise) {
          // Execute the re-authorization request and set the promise returned to refreshingPromise
          refreshingPromise = auth
            .silentAuth()
            .then((access_token) => {
              if (access_token) {
                localStorage.setItem('accessToken', access_token);

                // Return the new access token as a result of the promise
                return access_token;
              } else {
                history.push('/login');
              }
            })
            .catch(function () {
              history.push('/login');
            });
        }

        return refreshingPromise.then((newAccessToken: any) => {
          // Now that the refreshing promise has been executed, set it to null
          refreshingPromise = null;

          // Set the authorization header on the original options parameter to the new access token we got
          options.headers.authorization = `${newAccessToken}`;

          // Return the promise from the new fetch (which should now have used an active access token)
          // If the initialRequest had errors, this fetch that is returned below is the final result.
          return fetch(input, options);
        });
      }
      // If there were no errors in the initialRequest, we need to repackage the promise and return it as the final result.
      const result: any = {};
      result.ok = true;

      result.text = () =>
        new Promise((resolve) => {
          resolve(JSON.stringify(json));
        });

      return result;
    });
};

const cache = new InMemoryCache();

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE as string);

const httpLink = createHttpLink({
  uri: process.env.GATEWAY_URL,
  credentials: 'include',
  fetch: customFetch,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: auth.getAuthHeader(),
    },
  };
});

export const client = new ApolloClient({
  cache: cache,
  link: authLink.concat(httpLink),
  typeDefs,
  resolvers: {
    Query: {
      isLoggedIn() {
        return getData().isLoggedIn;
      },
      loggedInUserAuth0Id() {
        return getData().loggedInUserAuth0Id;
      },
      loggedInUserRole() {
        return getData().loggedInUserRole;
      },
      loggedInUserFirstname() {
        return getData().loggedInUserFirstname;
      },
      loggedInUserLastname() {
        return getData().loggedInUserLastname;
      },
    },
  },
});

export interface ClientData {
  isLoggedIn: boolean;
  loggedInUserAuth0Id: string;
  loggedInUserRole: string;
  loggedInUserFirstname: string;
  loggedInUserLastname: string;
}

export function getData() {
  const data: ClientData = {
    isLoggedIn: false,
    loggedInUserAuth0Id: '',
    loggedInUserRole: '',
    loggedInUserFirstname: '',
    loggedInUserLastname: '',
  };
  const isTokenValid: boolean = auth.isTokenValid();
  if (isTokenValid) {
    // @ts-ignore
    const decodedIdToken: any = auth.getIdTokenFromLocalStorage() && jwtDecode(auth.getIdTokenFromLocalStorage());
    // @ts-ignore
    const decodedAccessToken: any = auth.getIdTokenFromLocalStorage() && jwtDecode(auth.getAccessToken());
    data.isLoggedIn = isTokenValid;
    data.loggedInUserAuth0Id = decodedIdToken.sub;
    const scopes = decodedAccessToken.scope.split(' ');
    data.loggedInUserRole = scopes[scopes.length - 1];
    data.loggedInUserFirstname = decodedIdToken['https://talentnow.userinfo.com/usermetadata'].first_name;
    data.loggedInUserLastname = decodedIdToken['https://talentnow.userinfo.com/usermetadata'].last_name;
  }

  return data;
}

cache.writeQuery({
  query: GET_LOGGEDIN,
  data: getData(),
});

// @ts-ignore
client.onResetStore(() => {
  cache.writeQuery({
    query: GET_LOGGEDIN,
    data: getData(),
  });
});
const WrappedApp = (
  <ApolloProvider client={client}>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </ApolloProvider>
);

if (process.env.SENTRY_KEY) {
  Sentry.init({
    dsn:
      'https://' +
      process.env.SENTRY_KEY +
      '@' +
      process.env.SENTRY_ORGANIZATION_ID +
      '.ingest.sentry.io/' +
      process.env.SENTRY_PROJECT_ID,
  });
}

ReactDOM.render(WrappedApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
