import { ApolloError } from '@apollo/client';

const errorMessageFormatter = (error?: ApolloError) => {
  const graphQLErrors = error?.graphQLErrors;
  const networkError = error?.networkError;
  if (graphQLErrors) {
    return graphQLErrors
      .map(({ extensions, message }) => {
        const extensionsResponse: any = extensions?.response;
        const errorBody = extensionsResponse?.body;
        if (errorBody?.error) {
          return errorBody.message ? errorBody.message : errorBody.error;
        } else {
          console.error(message);
        }
      })
      .join('');
  }
  if (networkError) {
    return networkError.message;
  }
};

export default errorMessageFormatter;
