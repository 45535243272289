import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface IProps {
  src?: string;
  name: string;
  onClick?: any;
  className?: any;
  isCompletePath?: boolean;
}

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#000000',
    margin: 10,
    alignItems: 'center',
    width: 44,
    height: 44,
  },
  imageBroke: {
    display: 'none',
  },
});

function getInitials(name: string) {
  var initials: any = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
}

export default function TnAvatar(props: IProps) {
  const classes = useStyles();
  const [imgUrl, setImgUrl] = useState(props && props.src);

  useEffect(() => {
    setImgUrl(props.src);
  }, [props.src]);

  function addDefaultSrc(event: any) {
    setImgUrl('');
  }

  return (
    <Avatar
      className={props.className ? classNames(classes.avatar, props.className) : classes.avatar}
      onClick={props.onClick}
      onError={(event) => addDefaultSrc(event)}
      src={imgUrl ? `${props.isCompletePath ? '' : process.env.PROFILE_PIC_URL}` + props.src : ''}
    >
      {getInitials(props.name)}
    </Avatar>
  );
}
