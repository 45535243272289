import { Theme } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { CSSProperties } from 'react';

export const FONT_LIGHT: CSSProperties = {
  fontFamily: 'Avenir Light, Arial, Verdana, sans-serif',
  fontWeight: 200,
};

export const FONT_BOOK: CSSProperties = {
  fontFamily: 'Avenir Book, Arial, Verdana, sans-serif',
  fontWeight: 300,
};

export const FONT_REG: CSSProperties = {
  fontFamily: 'Avenir, Arial, Verdana, sans-serif',
  fontWeight: 400,
};

export const FONT_MED: CSSProperties = {
  fontFamily: 'Avenir Medium, Arial, Verdana, sans-serif',
  fontWeight: 500,
};

export const FONT_HEAVY: CSSProperties = {
  fontFamily: 'Avenir Heavy, Arial, Verdana, sans-serif',
  fontWeight: 800,
};

export const FONT_BOLD: CSSProperties = {
  fontFamily: 'Avenir Medium, Arial, Verdana, sans-serif',
  fontWeight: 700,
};

export const FONT_BLACK: CSSProperties = {
  fontFamily: 'Avenir Black, Arial, Verdana, sans-serif',
  fontWeight: 800,
};

export const COLOR_PRIMARY = 'rgb(244, 93, 34)';
export const COLOR_SECONDARY = 'rgb(244, 93, 34, 0.9)';
export const COLOR_CARD_HEADER_BACKGROUND = '#F2F2F2';
export const COLOR_BAR_CHART_GREEN = '#228B22';
export const COLOR_BAR_CHART_RED = '#FF0000';
export const COLOR_BAR_CHART_GREY = '#979FAA';

export const COLOR_PRIMARY_KROGER = 'rgb(68,113,184)';
export const COLOR_SECONDARY_KROGER = 'rgb(68,113,184, 0.9)';

export const COLOR_PRIMARY_TN = 'rgb(4, 178, 182)';
export const COLOR_SECONDARY_TN = 'rgb(4, 178, 182, 0.9)';

export const getLinkStyling = (theme: Theme) => {
  return {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  };
};

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: COLOR_SECONDARY,
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Avenir',
      'Avenir Light',
      'Avenir Medium',
      'Avenir Heavy',
      'Avenir Book',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(','),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#1C1E20',
      },
      containedPrimary: {
        color: '#FFFFFF',
        backgroundColor: COLOR_PRIMARY,
        boxShadow: 'none',
        border: `1px solid ${COLOR_PRIMARY}`,
        fontSize: '14px',
        ...FONT_MED,
        lineHeight: '16px',
        borderRadius: '8px',
        '&:hover': {
          opacity: 0.9,
          backgroundColor: COLOR_PRIMARY,
          boxShadow: 'none',
        },
        '&:disabled': {
          color: '#1C1E20 !important',
          backgroundColor: '#eaecee !important',
          border: '1px solid #eaecee !important',
        },
      },
      containedSecondary: {
        color: '#ffffff',
        backgroundColor: COLOR_PRIMARY,
        boxShadow: 'none',
        border: `1px solid ${COLOR_PRIMARY}`,
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '14px',
        '&:hover': {
          opacity: 0.9,
          backgroundColor: COLOR_PRIMARY,
          boxShadow: 'none',
          border: `1px solid ${COLOR_PRIMARY}`,
        },
        '&:disabled': {
          color: '#1C1E20',
          backgroundColor: '#eaecee',
          border: '1px solid #eaecee',
        },
      },
      outlinedPrimary: {
        color: '#1C1E20 !important',
        boxSizing: 'border-box',
        border: '2px solid #A8A8A8 !important',
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#EAECEE !important',
          border: '2px solid #A8A8A8 !important',
        },
      },
      outlinedSecondary: {
        color: COLOR_PRIMARY,
        boxSizing: 'border-box',
        border: `2px solid ${COLOR_PRIMARY}`,
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#fff0d0',
          border: `2px solid ${COLOR_PRIMARY}`,
        },
      },
      textPrimary: {
        textTransform: 'none',
        fontSize: '11px',
        lineHeight: '13px',
        color: '#ACB6BF',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      textSecondary: {
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '16px',
        color: COLOR_PRIMARY,
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: '#1C1E20',
        backgroundColor: 'none',
        '&:hover': {
          backgroundColor: 'none',
        },
        '&$focused': {
          backgroundColor: 'none',
        },
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #d1d1d1',
        },
        '&:after': {
          borderBottom: `2px solid ${COLOR_PRIMARY}`,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #d1d1d1',
        },
        '&:after': {
          borderBottom: `2px solid ${COLOR_PRIMARY}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 18.6667,
        '&$focused': {
          color: COLOR_PRIMARY,
        },
        color: '#1C1E20',
        '&$error': {
          color: '#1C1E20 !important',
        },
      },
    },
    MuiInputBase: {
      multiline: {
        padding: 0,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: lighten(COLOR_PRIMARY, 0.5),
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#1C1E20',
      },
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded': {
          backgroundColor: '#F9F9FB',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: '#F9F9FB',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          margin: 'auto',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '6px',
      },
    },
    MuiTableCell: {
      root: {
        paddingRight: 0,
      },
    },
    MuiTab: {
      root: {
        ...FONT_HEAVY,
      },
    },
    MuiSlider: {
      mark: {
        backgroundColor: 'white',
      },
    },
    // @ts-ignore
    PrivateValueLabel: {
      label: {
        color: 'white',
      },
    },
    MuiTypography: {
      caption: {
        ...FONT_REG,
      },
    },
    MuiPopover: {
      root: {
        zIndex: '10007 !important' as any,
      },
      paper: {
        zIndex: '10007 !important' as any,
      },
    },
  },
});

export const krogerTheme = createMuiTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY_KROGER,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: COLOR_SECONDARY_KROGER,
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Avenir',
      'Avenir Light',
      'Avenir Medium',
      'Avenir Heavy',
      'Avenir Book',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(','),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#1C1E20',
      },
      containedPrimary: {
        color: '#FFFFFF',
        backgroundColor: COLOR_PRIMARY_KROGER,
        boxShadow: 'none',
        border: `1px solid ${COLOR_PRIMARY_KROGER}`,
        fontSize: '14px',
        ...FONT_MED,
        lineHeight: '16px',
        borderRadius: '4px',
        '&:hover': {
          opacity: 0.9,
          backgroundColor: COLOR_PRIMARY_KROGER,
          boxShadow: 'none',
        },
        '&:disabled': {
          color: '#1C1E20 !important',
          backgroundColor: '#eaecee !important',
          border: '1px solid #eaecee !important',
        },
      },
      containedSecondary: {
        color: '#ffffff',
        backgroundColor: COLOR_PRIMARY_KROGER,
        boxShadow: 'none',
        border: `1px solid ${COLOR_PRIMARY_KROGER}`,
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '14px',
        '&:hover': {
          opacity: 0.9,
          backgroundColor: COLOR_PRIMARY_KROGER,
          boxShadow: 'none',
          border: `1px solid ${COLOR_PRIMARY_KROGER}`,
        },
        '&:disabled': {
          color: '#1C1E20',
          backgroundColor: '#eaecee',
          border: '1px solid #eaecee',
        },
      },
      outlinedPrimary: {
        color: '#1C1E20 !important',
        boxSizing: 'border-box',
        border: '2px solid #A8A8A8 !important',
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: '#EAECEE !important',
          border: '2px solid #A8A8A8 !important',
        },
      },
      outlinedSecondary: {
        color: COLOR_PRIMARY_KROGER,
        boxSizing: 'border-box',
        border: `2px solid ${COLOR_PRIMARY_KROGER}`,
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: lighten(COLOR_PRIMARY_KROGER, 0.8),
          border: `2px solid ${COLOR_PRIMARY_KROGER}`,
        },
      },
      textPrimary: {
        textTransform: 'none',
        fontSize: '11px',
        lineHeight: '13px',
        color: '#ACB6BF',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      textSecondary: {
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '16px',
        color: COLOR_PRIMARY_KROGER,
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: '#1C1E20',
        backgroundColor: 'none',
        '&:hover': {
          backgroundColor: 'none',
        },
        '&$focused': {
          backgroundColor: 'none',
        },
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #d1d1d1',
        },
        '&:after': {
          borderBottom: `2px solid ${COLOR_PRIMARY_KROGER}`,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #d1d1d1',
        },
        '&:after': {
          borderBottom: `2px solid ${COLOR_PRIMARY_KROGER}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 18.6667,
        '&$focused': {
          color: COLOR_PRIMARY_KROGER,
        },
        color: '#1C1E20',
        '&$error': {
          color: '#1C1E20 !important',
        },
      },
    },
    MuiInputBase: {
      multiline: {
        padding: 0,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: lighten(COLOR_PRIMARY_KROGER, 0.5),
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#1C1E20',
      },
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded': {
          backgroundColor: '#F9F9FB',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: '#F9F9FB',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          margin: 'auto',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '6px',
      },
    },
    MuiTableCell: {
      root: {
        paddingRight: 0,
      },
    },
    MuiTab: {
      root: {
        ...FONT_HEAVY,
      },
    },
    MuiSlider: {
      mark: {
        backgroundColor: 'white',
      },
    },
    // @ts-ignore
    PrivateValueLabel: {
      label: {
        color: 'white',
      },
    },
    MuiTypography: {
      caption: {
        ...FONT_REG,
      },
    },
    MuiPopover: {
      root: {
        zIndex: '10007 !important' as any,
      },
      paper: {
        zIndex: '10007 !important' as any,
      },
    },
  },
});

export const talentNowTheme = createMuiTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY_TN,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: COLOR_SECONDARY_TN,
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Avenir',
      'Avenir Light',
      'Avenir Medium',
      'Avenir Heavy',
      'Avenir Book',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(','),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#1C1E20',
      },
      containedPrimary: {
        color: '#FFFFFF',
        backgroundColor: COLOR_PRIMARY_TN,
        boxShadow: 'none',
        border: `1px solid ${COLOR_PRIMARY_TN}`,
        fontSize: '14px',
        ...FONT_MED,
        lineHeight: '16px',
        borderRadius: '4px',
        '&:hover': {
          opacity: 0.9,
          backgroundColor: COLOR_PRIMARY_TN,
          boxShadow: 'none',
        },
        '&:disabled': {
          color: '#1C1E20 !important',
          backgroundColor: '#eaecee !important',
          border: '1px solid #eaecee !important',
        },
      },
      containedSecondary: {
        color: '#ffffff',
        backgroundColor: COLOR_PRIMARY_TN,
        boxShadow: 'none',
        border: `1px solid ${COLOR_PRIMARY_TN}`,
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '14px',
        '&:hover': {
          opacity: 0.9,
          backgroundColor: COLOR_PRIMARY_TN,
          boxShadow: 'none',
          border: `1px solid ${COLOR_PRIMARY_TN}`,
        },
        '&:disabled': {
          color: '#1C1E20',
          backgroundColor: '#eaecee',
          border: '1px solid #eaecee',
        },
      },
      outlinedPrimary: {
        color: '#1C1E20 !important',
        boxSizing: 'border-box',
        border: '2px solid #A8A8A8 !important',
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: '#EAECEE !important',
          border: '2px solid #A8A8A8 !important',
        },
      },
      outlinedSecondary: {
        color: COLOR_PRIMARY_TN,
        boxSizing: 'border-box',
        border: `2px solid ${COLOR_PRIMARY_TN}`,
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: lighten(COLOR_PRIMARY_TN, 0.8),
          border: `2px solid ${COLOR_PRIMARY_TN}`,
        },
      },
      textPrimary: {
        textTransform: 'none',
        fontSize: '11px',
        lineHeight: '13px',
        color: '#ACB6BF',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      textSecondary: {
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '16px',
        color: COLOR_PRIMARY_TN,
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: '#1C1E20',
        backgroundColor: 'none',
        '&:hover': {
          backgroundColor: 'none',
        },
        '&$focused': {
          backgroundColor: 'none',
        },
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #d1d1d1',
        },
        '&:after': {
          borderBottom: `2px solid ${COLOR_PRIMARY_TN}`,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #d1d1d1',
        },
        '&:after': {
          borderBottom: `2px solid ${COLOR_PRIMARY_TN}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 18.6667,
        '&$focused': {
          color: COLOR_PRIMARY_TN,
        },
        color: '#1C1E20',
        '&$error': {
          color: '#1C1E20 !important',
        },
      },
    },
    MuiInputBase: {
      multiline: {
        padding: 0,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: lighten(COLOR_PRIMARY_TN, 0.5),
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#1C1E20',
      },
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded': {
          backgroundColor: '#F9F9FB',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: '#F9F9FB',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          margin: 'auto',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '6px',
      },
    },
    MuiTableCell: {
      root: {
        paddingRight: 0,
      },
    },
    MuiTab: {
      root: {
        ...FONT_HEAVY,
      },
    },
    MuiSlider: {
      mark: {
        backgroundColor: 'white',
      },
    },
    // @ts-ignore
    PrivateValueLabel: {
      label: {
        color: 'white',
      },
    },
    MuiTypography: {
      caption: {
        ...FONT_REG,
      },
    },
    MuiPopover: {
      root: {
        zIndex: '10007 !important' as any,
      },
      paper: {
        zIndex: '10007 !important' as any,
      },
    },
  },
});
