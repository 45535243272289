import { useMutation, useQuery } from '@apollo/client';
import { FetchPolicy } from '@constants/FetchPolicy';
import {
  checkIfJobExist as CheckIfJobExistType,
  editJob as EditJobType,
  getJobTitles as GetJobTitlesType,
} from '@generated/types';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { history } from '../../_helpers/history';
import { checkIfJobExist, editJob, GetJobTitles } from '../../_services/jobs/queries';
import { client } from '../../index';
import errorMessageFormatter from '../../utils/errorMessageFormatter';
import Loader from '../Loader';
import TnSnackBar from '../snackbar/TnSnackBar';
import TnBackButton from '../tn-back-button/TnBackButton';
import TnButton from '../tn-button/TnButton';
import TnSelect from '../tn-select/TnSelect';
import TnTextField from '../tn-text-field/TnTextField';
import TnTypography from '../tn-typography/TnTypography';

interface Props {
  location: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      height: 100,
    },
  }),
);

export default function EditJob(props: Props) {
  const classes = useStyles();

  const [jobId, setJobId] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [jobTitle, setJobTitle] = useState({
    label: '',
    value: '',
    job_category_id: '',
  });
  const [jobTitleOptions, setJobTitleOptions] = useState<Array<any>>([]);
  const [alternateTitle, setAlternateTitle] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hirevuePositionId, setHirevuePositionId] = useState('');
  const { data: jobTitleOptionsData } = useQuery<GetJobTitlesType>(GetJobTitles);
  const [jobExists, setJobExists] = useState(false);
  const [editJobReq, { loading: editJobLoading, data: editJobResponse, error: editJobError }] =
    useMutation<EditJobType>(editJob);

  useEffect(() => {
    setJobId(props.location.state.job?.id);
    setAlternateTitle(props.location.state.job?.alternateTitle);
    setHirevuePositionId(props.location.state.job?.hirevuePositionId);
  }, []);

  useEffect(() => {
    if (jobTitleOptionsData?.getJobTitles) {
      const jobTitles: Array<any> = [];
      jobTitleOptionsData.getJobTitles.forEach((title) => {
        jobTitles.push({
          label: title.name,
          value: title.id,
          job_category_id: title.job_category_id,
        });
        if (title.name == props.location.state.job?.jobTitle) {
          setJobTitle({
            label: title.name || '',
            value: title.id || '',
            job_category_id: title.job_category_id || '',
          });
        }
      });
      setJobTitleOptions(jobTitles);
    }
  }, [jobTitleOptionsData]);

  useEffect(() => {
    if (editJobResponse) history.push('/jobs');
  }, [editJobResponse]);

  useEffect(() => {
    const error = errorMessageFormatter(editJobError);
    if (error) {
      if (error == 'Invalid Hirevue Position ID') {
        setIsSnackbarOpen(true);
        setSnackbarMessage('Invalid Hirevue Position Id');
      } else {
        setIsSnackbarOpen(true);
        setSnackbarMessage('Something went wrong');
      }
    }
  }, [editJobError]);

  function handleEditJob() {
    editJobReq({
      variables: {
        input: {
          id: jobId,
          job_title_id: jobTitle.value,
          alternate_title: alternateTitle,
          hirevue_position_id: hirevuePositionId,
          job_category_id: jobTitle.job_category_id,
        },
      },
    });
  }

  useEffect(() => {
    if (
      jobTitle.value != '' &&
      alternateTitle != '' &&
      (jobTitle.label != props.location.state.job?.jobTitle ||
        alternateTitle != props.location.state.job?.alternateTitle)
    ) {
      client
        .query<CheckIfJobExistType>({
          query: checkIfJobExist,
          fetchPolicy: FetchPolicy.NETWORK_ONLY,
          variables: {
            job_title_id: jobTitle.value,
            alternate_title: alternateTitle,
          },
        })
        .then((response) => {
          if (response.data.checkIfJobExist) {
            setIsSnackbarOpen(true);
            setSnackbarMessage('Job already exists');
            setJobExists(true);
          } else {
            setJobExists(false);
          }
        });
    }
  }, [jobTitle, alternateTitle]);

  function closeSnackBar() {
    setIsSnackbarOpen(false);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify={'center'}>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container={true} alignItems={'center'} className={classes.header} spacing={2}>
                  <Grid item>
                    <Grid container item alignItems={'center'}>
                      <TnBackButton />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container item alignItems={'center'}>
                      <TnTypography variant={'h2'}>Edit Job</TnTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify={'center'}>
                  <Grid item xs={11}>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <TnSelect
                          label="Job Title"
                          required={true}
                          options={jobTitleOptions}
                          onChange={(value: any) => setJobTitle(value)}
                          value={jobTitle}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TnTextField
                          label="Alternate Title"
                          required={true}
                          value={alternateTitle}
                          onChange={(value: any) => setAlternateTitle(value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TnTextField
                          label="Hirevue Position ID"
                          required={true}
                          value={hirevuePositionId}
                          onChange={(value: any) => setHirevuePositionId(value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container justify={'flex-end'}>
                      <TnButton
                        value={'submit'}
                        variant={'contained'}
                        color={'primary'}
                        disabled={!(jobTitle.value && alternateTitle && hirevuePositionId && !jobExists)}
                        onClick={() => handleEditJob()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TnSnackBar open={isSnackbarOpen} variant={'error'} message={snackbarMessage} handleClose={closeSnackBar} />
          {editJobLoading && <Loader />}
        </Grid>
      </Grid>
    </Grid>
  );
}
